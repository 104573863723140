.image-tile img {
    padding: .3rem;
    background-color: #16273d;
    border: 1px solid #16273d;
    border-radius: .9rem;
    max-width: 100%;
    text-align: center;
}

.image-tile .title {
    text-transform: uppercase;
    color:  #16273d !important;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    margin-top: 0.21em;
    font-weight: 600;
}
