.section-header {
    margin-bottom: 3em;
    margin-top: 3em;
}

.section-header .title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 1000;
    color: #2b4265;
}
.section-header .sub-title {
    font-size: 1.3em;
    font-weight: 600;
    font-style: italic;
    color: #2b4265;
}
.section-header .separator {
    border: solid 1px #346ab4;
    margin-bottom: 4em;
}
