.region-tile .hover-image {
    display: none;
    width: 210px;
    height: 278px;
}

.region-tile:hover .hover-image {
    display: block;
    width: 210px;
    height: 278px;
}

.region-tile:hover .off-image {
    display: none;
    width: 210px;
    height: 278px;
}

.region-tile .off-image {
    display: block;
    width: 210px;
    height: 278px;
}

.region-tile img {
    margin-left: auto;
    margin-right: auto;
    width: 210px;
    height: 278px;
}
