.dashboard-icon  {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 1em;
    font-weight: 800;
    color: #2b4265;
    display: flex;
    text-decoration: none;
}

.dashboard-icon:hover {
    color: #356ab4 !important;
}

.dashboard-link:hover {
    text-decoration: none;
    color: #356ab4 !important;
}

.dashboard-icon img {
    margin-right: 2em;
    height: 50px;
}

.dashboard-icon .img-container {
    text-align: center;
}
.dashboard-icon .hover-icon {
    display: none;
}

.dashboard-icon:hover .hover-icon {
    display: block;
}

.dashboard-icon:hover .off-icon {
    display: none;
}

.dashboard-icon .sub-title {
    font-weight: 500;
}
