.link-section {
    margin-bottom: 3em;
}

.link-section .content {
    margin-left: 2em;
    padding-left: 2em;
}

.link-section .title {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 1.2em;
    font-weight: 800;
    font-style: italic;
    margin-bottom: 1em;
    color: #2b4265;
}

.link-section-icon  {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 1.2em;
    font-weight: 500;
    color: #2b4265;
    display: flex;
    text-decoration: none;
    margin-bottom: 1em;
}

.link-section-icon:hover {
    color: #356ab4 !important;
}

.dashboard-link:hover {
    text-decoration: none;
    color: #356ab4 !important;
}

.link-section-icon img {
    margin-right: 1em;
    height: 45px;
}

.link-section-icon .img-container {
    text-align: center;
}
.link-section-icon .hover-icon {
    display: none;
}

.link-section-icon:hover .hover-icon {
    display: block;
}

.link-section-icon:hover .off-icon {
    display: none;
}

.link-section-icon .sub-title {
    font-weight: 500;
}
