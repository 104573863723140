.breadcrumb {

    background-color: white;
}

.bc-element {
    /* font-family: 'Open Sans', sans-serif; */
    margin-right: 0.5em;
    font-size: 1em;
    color: #2b4265;
    font-weight: bold;
}
