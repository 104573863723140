
.banner-container {
    background-image: linear-gradient(to right, #0C1323 25%, #1C2435 75%);
    background-size: 100%;
    background-repeat: no-repeat;
    /* background-color: #0c1323; */
    background-position: center center;
    height: 100%;

}

.banner-inner-container {
    display: flex;
    justify-content: center;
}

.globe-ring {
    padding: 0 9px 0 0;
    margin-top: 16px;
    height: 60px;
}

.globe {
    float: right;
    height: 100px;
}

.banner-text-image {
    padding: 0 9px 0 0;
    margin-top: 28px;
    height: 50px;
}

.banner-image {
    /* padding-right: 900px;
    margin-right: 28px;
    height: 20%; */
    max-width: 100%;
    max-height: 125px;

}

.banner-text {
    flex: auto;
}

.banner-title {
    font-size: 1.45em;
}

.banner-subtitle {
    font-size: 0.7em;
}

a {
    text-decoration: none;
}
