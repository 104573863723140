
.co-div{
   padding-left: 2px;
   padding-right: 2px;
}

.co-tagline {
    width: 100%;
    background-color: white;
    font-weight: 800;
    text-align: left;

    font-family: 'Open Sans', sans-serif;
    color: #2b4265;
    font-weight: inherit;
    font-style: italic;
    display: table;
    border: solid 0.1em #283758;
    border-radius: 1px;
}

.section-header {
    margin-bottom: .5em;
    margin-top: .5em;
    font-style: italic;
    font-weight: 400;
}

.section-header .title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 1000;
    color: #2b4265;
}
.section-header .sub-title {
    font-size: 1.3em;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: italic;
    color: #2b4265;
}
.section-header .separator {
    border: solid 2px #346ab4;
    margin-bottom: 2em;
}