.quickstart-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    text-align: left;
    color: #6d431e;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-weight: bolder;
}

.quickstart-sub-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    text-align: left;
    color: #6d431e;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.quickstart-sub-header a {
    color: #6d431e;
    font-weight: bolder;
    text-decoration: underline;
}

.other-views-header {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em !important;
    font-weight: bold;
    font-style: italic;
    text-align: left;
    color: #346ab4 !important;
}