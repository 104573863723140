.imap-tagline {
    width: 100%;
    background-color: white;
    font-weight: 800;
    text-align: left;
    margin-top: 100px;

    color: #2b4265;
    font-weight: inherit;
    font-style: italic;
    display: table;
    border-radius: 1px;
}