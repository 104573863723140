.featured-work-products .slide-container {
  background-color: white;
  width: 100%;
  padding-bottom: 24px;
}

.featured-work-products .slide-content {
  width: 80%;
  background-color: white;
  margin: auto;
  padding: 0.3em;
  border: solid 0.2em #283758;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.featured-work-products .slide-content .slide-text {
  color: #356ab4;
  /* font-family: "Open Sans", sans-serif; */
  /* font-size: 1.5em; */
  font-weight: 800;
  display: table-cell;
  vertical-align: middle;
}

.featured-work-products .slide-content img {
  height: 6em;
  padding: 0.5em;
}

.featured-link:hover {
  text-decoration: none;
}

.file-type-image {
  height: 4em !important;
}

/* Slick Slider UI */

div.slick-slider {
  .slick-dots {
    .slick-active {
      margin: 0;
    }

    li {
      margin: 0;
      width: 12px;
      height: 12px;
      button {
        width: 12px;
        height: 12px;
        padding: 0;

        &:before {
          line-height: 12px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
