.work-product-panel {
    border-radius: 10px;
    border: solid 1px #356ab4;
}

.work-product-panel .header{
    background-color: #356ab4;
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    text-transform: uppercase;
    /* font-family: 'EB Garamond', serif; */
    font-style: normal;
    font-weight: bold;
}

.work-product-panel .wp-footer {
    background-color: #356ab4;
}


.accordion {
    padding: .25em;
    cursor: pointer;
    border-bottom: solid 1px #356ab4;
}

.accordion-header {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 1.2em;
    color: #356ab4;
    font-weight: bold;
}

.accordion-icon {
    margin-right: 1em ;
}

.card-header {
    font-size: 1.2em;
}

.accordion-content {
    border: none;
}


.work-product-row {
    cursor: pointer;
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 1.0em;
    color: #356ab4;
    background-color: #D0D3DB;
    padding-left: 3.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: solid 1px #356ab4;
    align-items: center;
    display: flex;

}

.work-product-row-icon {
    height: 25px;
    margin-right: 1em;
}

.work-product-file-type-icon {
    height: 25px;
    margin-left: 1em;
}
