.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*body {
  background: url('/img/dashboard.png') no-repeat center center;
  background-size: cover;
}*/

#root {
  padding-bottom: 56px;
  position: relative;
  min-height: 100vh;
}

.content-wrapper {
  padding-bottom: 4em;;
}

.navigation-banner {
  background-color: #2b4265;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 1em;
  border-top: 2px solid #F2BF10;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-content {
  background-color: #2b4265;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem 1rem;
}

.navigation-banner .nav-link {
  color: white !important;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 1.4em;
  font-weight: 400;
}

.footer-content .nav-link {
  color: white !important;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 1em;
  font-weight: 400;
  padding: 0.5rem 1rem;
}

.navigation-banner .nav-link:hover, .footer-content .nav-link:hover {
  color: #99d2f3 !important;
}

.dropdown-menu {
  background-color: #99d2f3;
  border-radius: 0;
  font-weight: bolder;
}

.dropdown-item {
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 1em;
  font-weight: 600;
  color: #2b4265
}
.dropdown-item:hover {
  color: #6d431e;
}

