.dashboard-big-icon  {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: .95em;
    font-weight: 800;
    color: #2b4265;
    text-decoration: none;
    text-align: center;
}

.dashboard-big-icon:hover {
    color: #356ab4 !important;
}

.dashboard-link:hover {
    text-decoration: none;
    color: #356ab4 !important;
}

.dashboard-big-icon img {
    margin-right: 2em;
    height: 35px;
}

.dashboard-big-icon .img-container {
    text-align: center;
    height: 120px;
    max-height: 120px;
    margin-bottom: 0.6em;
}
.dashboard-big-icon .hover-icon {
    display: none;
}

.dashboard-big-icon:hover .hover-icon {
    display: block;
}

.dashboard-big-icon:hover .off-icon {
    display: none;
}

.dashboard-big-icon .off-icon {
    display: block;
}

.dashboard-big-icon .sub-title {
    font-weight: 500;
}

.dashboard-big-icon .title {
    text-transform: uppercase;
}

.dashboard-big-icon img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    height: 100%;
    width: auto;
    vertical-align: middle;
    display: inline-block;
    padding: 0.6em;
}
