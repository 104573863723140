
.imap-tagline {
    /* font-family: 'Open Sans'; */
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: #0c1323;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
